import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/react-demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// pages
import Menu from "views/Menu.js";
import Escrituracion from "views/videos/Escrituracion.js";
import Configuracion from "views/videos/Configuracion.js";
import Cartera from "views/videos/Cartera.js";
import Depositos from "views/videos/Depositos.js";
import CajaRapida from "views/videos/Caja_rapida.js";
import RegistroCivil from "views/videos/Registro_civil.js";
import ActosVarios from "views/videos/Actos_varios.js";
import Contabilidad from "views/videos/Contabilidad.js";
import ProcesosUtilitarios from "views/videos/Procesos_utilitarios.js";
import ListadosInformes from "views/videos/Listados_informes.js";
import Corporativos from "views/videos/Corporativos.js";
import DropdownWhiteNavbar from "components/Navbars/DropdownWhiteNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js"

// others

ReactDOM.render(
  <BrowserRouter>
    <div className="wrapper">
      <DropdownWhiteNavbar />
        <Routes>
          <Route
            path="/corporativos" element={<Corporativos/>}
          />
          <Route
            path="/listadosInformes" element={<ListadosInformes/>}
          />
          <Route
            path="/procesosUtilitarios" element={<ProcesosUtilitarios/>}
          />
          <Route
            path="/contabilidad" element={<Contabilidad/>}
          />
          <Route
            path="/actos_varios" element={<ActosVarios/>}
          />
          <Route
            path="/registro_civil" element={<RegistroCivil/>}
          />
          <Route
            path="/caja_rapida" element={<CajaRapida/>}
          />
          <Route
            path="/depositos" element={<Depositos/>}
          />
          <Route
            path="/cartera" element={<Cartera/>}
          />
          <Route
            path="/configuracion" element={<Configuracion/>}
          />
          <Route
            path="/escrituracion" element={<Escrituracion/>}
          />
          <Route
            path="/" element={<Menu/>} 
          />
        </Routes>
      <FooterBlack />
    </div>
  </BrowserRouter>,
  document.getElementById("root")
);
