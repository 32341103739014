import React from "react";
import Iframe from "react-iframe";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";

// core components

function Corporativos() {

  return (
    <>
      <div className="section" style={{ backgroundColor: "White", marginTop:"1%" }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <h2 className="text-center title" style={{ color: "#3e4095" }}>
                Corporativos <br></br>
              </h2>
              <div className="space-25"></div>
            </Col>
          </Row>
          <Row className="justify-content-lg-center">
            <Col className="ml-auto mr-auto text-center" lg="12">
              <Card
                style={{
                  width: "100%"
                }}
                className="text-center"
              >
                <CardBody>
                  {/*
                    .##.....##.####.########..########..#######...######.
                    .##.....##..##..##.....##.##.......##.....##.##....##
                    .##.....##..##..##.....##.##.......##.....##.##......
                    .##.....##..##..##.....##.######...##.....##..######.
                    ..##...##...##..##.....##.##.......##.....##.......##
                    ...##.##....##..##.....##.##.......##.....##.##....##
                    ....###....####.########..########..#######...######.
                    */}
                      <Row className="justify-content-center">
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/FwnD3l3t5T0" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                            Definición de Proyectos
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/itvPndS_noY" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                            Informe Constructoras 3 0
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/JD8QGvU27Bw" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              QR
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/pggEz4JwcNQ" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Cuentas de Cobro diarias de Clientes Corporativos
                            </h6>
                        </Col>
                      </Row>
                      {/* <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/ugxCocs75zE" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                                TÍTULO
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/ugxCocs75zE" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                                TÍTULO
                            </h6>
                        </Col>
                      </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Corporativos;
