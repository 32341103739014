import React from "react";
// javascript library that creates a parrallax effect
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
// reactstrap components

// core components

// sections for this page
// import Tutoriales from "./videos/Tutoriales.js";
// import Cotizador from "./presentation-sections/Cotizador.js";
// import Galeria from "./presentation-sections/Galeria.js";
// import Contacto from "./presentation-sections/Contacto.js";

function Menu() {
  return (
    <>
      <div  className="section" style={{ backgroundColor: "White", marginTop: "1%"}}>
        <Container>
          <Row className = "justify-content-center">
            <Col className="ml-auto mr-auto text-center" md="12">
              <img alt="..."  style={{width: "125px"}} src={require("assets/img/Sinfony.png")}></img>
            </Col>
            <Col className="ml-auto mr-auto" md="12">
              <h2 className="text-center title" style={{ color: "#031425" }}>
                TUTORIALES 
              </h2>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row className = "justify-content-center">
              <Col className="ml-auto mr-auto text-center" lg="12">
                <Card style={{width: "100%"}} className="text-center">
                  <CardBody>
                    <Row className = "justify-content-center">
                      <Col md= "3">
                        <a href= "/escrituracion">
                          <Card >
                            <CardBody className="rojo">
                              <i className="now-ui-icons files_paper"  style={{fontSize: "60px"}}></i>
                              <h3 className= "info-title">Escrituración</h3>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                      <Col md= "3">
                        <a href= "/configuracion">
                          <Card >
                            <CardBody className="rojo">
                              <i className="now-ui-icons loader_gear" style={{ fontSize: "60px"}}></i>
                              <h3 className= "info-title">Configuración</h3>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                      <Col md= "3">
                        <a href= "/cartera">
                          <Card >
                            <CardBody className="rojo">
                              <i className="now-ui-icons business_chart-bar-32" style={{ fontSize: "60px"}}></i>
                              <h3 className= "info-title">Cartera</h3>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                      <Col md= "3">
                        <a href= "/depositos"> 
                          <Card >
                            <CardBody className="rojo">
                              <i className="now-ui-icons business_globe" style={{ fontSize: "60px"}}></i>
                              <h3 className= "info-title">Depósitos</h3>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                    </Row>
                    <Row className = "justify-content-center">
                      <Col md= "3">
                        <a href= "/caja_rapida">
                          <Card  >
                            <CardBody className="rojo">
                              <i className="now-ui-icons files_box" style={{ fontSize: "60px"}}></i>
                              <h3 className= "info-title">Caja rápida</h3>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                      <Col md= "3">
                        <a href= "/registro_civil">
                          <Card >
                            <CardBody className="rojo">
                              <i className="now-ui-icons files_single-copy-04" style={{ fontSize: "60px"}}></i>
                              <h3 className= "info-title">Registro civil</h3>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                      <Col md= "3">
                        <a href= "/actos_varios">
                          <Card >
                            <CardBody className="rojo">
                              <i className="now-ui-icons ui-2_settings-90" style={{ fontSize: "60px"}}></i>
                              <h3 className= "info-title">Actos varios</h3>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                      <Col md= "3">
                        <a href= "/contabilidad"> 
                          <Card >
                            <CardBody className="rojo">
                              <i className="now-ui-icons business_chart-pie-36" style={{ fontSize: "60px"}}></i>
                              <h3 className= "info-title">Contabilidad</h3>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                    </Row>
                    <Row className = "justify-content-center">
                      <Col md= "3">
                        <a href= "/procesosUtilitarios">
                          <Card >
                            <CardBody className="rojo">
                              <i className="now-ui-icons business_briefcase-24" style={{ fontSize: "60px"}}></i>
                              <h3 className= "info-title">Procesos utilitarios</h3>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                      <Col md= "3">
                        <a href= "/listadosInformes"> 
                          <Card >
                            <CardBody className="rojo">
                              <i className="now-ui-icons education_paper" style={{ fontSize: "60px"}}></i>
                              <h3 className= "info-title">Listados e informes</h3>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                      <Col md= "3">
                        <a href= "/corporativos"> 
                          <Card >
                            <CardBody className="rojo">
                              <i className="now-ui-icons business_badge" style={{ fontSize: "75px"}}></i>
                              <h3 className= "info-title">Corporativos</h3>
                              <br></br>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Menu;
