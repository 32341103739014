import React from "react";
import Iframe from "react-iframe";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";

// core components

function Contabilidad() {

  return (
    <>
      <div className="section" style={{ backgroundColor: "White", marginTop:"1%" }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <h2 className="text-center title" style={{ color: "#3e4095" }}>
                Contabilidad <br></br>
              </h2>
              <div className="space-25"></div>
            </Col>
          </Row>
          <Row className="justify-content-lg-center">
            <Col className="ml-auto mr-auto text-center" lg="12">
              <Card
                style={{
                  width: "100%"
                }}
                className="text-center"
              >
                <CardBody>
                  {/*
                    .##.....##.####.########..########..#######...######.
                    .##.....##..##..##.....##.##.......##.....##.##....##
                    .##.....##..##..##.....##.##.......##.....##.##......
                    .##.....##..##..##.....##.######...##.....##..######.
                    ..##...##...##..##.....##.##.......##.....##.......##
                    ...##.##....##..##.....##.##.......##.....##.##....##
                    ....###....####.########..########..#######...######.
                    */}
                      <Row className="justify-content-center">
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/ElSPrJAlFgk" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Comprobante de Ingresos Archivo plano
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/4JX49bqvRzQ" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Comprobantes de Ingreso Diario
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/KfY3pF3YXeE" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Consultas e Informes
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/y6QgRZ499SU"
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Modificar, Anular Movimientos y cierre anual
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/pQbcuoln4ww" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Movimientos
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/v0lKK2Ls0vs" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Puc
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/089lw0oNb3Q" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Tipos de Movimientos Contables
                            </h6>
                        </Col>
                      </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Contabilidad;
