/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Designed by{" "}
            <a
              href="https://www.sinfony.com.co/sinfony/"
              target="_blank"
            >
              SINFONY Todos los derechos reservados.
            </a>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
