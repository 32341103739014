import React from "react";
import Iframe from "react-iframe";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";

// core components

function Cartera() {

  return (
    <>
      <div className="section" style={{ backgroundColor: "White", marginTop:"1%" }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <h2 className="text-center title" style={{ color: "#3e4095" }}>
                Cartera <br></br>
              </h2>
              <div className="space-25"></div>
            </Col>
          </Row>
          <Row className="justify-content-lg-center">
            <Col className="ml-auto mr-auto text-center" lg="12">
              <Card
                style={{
                  width: "100%"
                }}
                className="text-center"
              >
                <CardBody>
                  {/*
                    .##.....##.####.########..########..#######...######.
                    .##.....##..##..##.....##.##.......##.....##.##....##
                    .##.....##..##..##.....##.##.......##.....##.##......
                    .##.....##..##..##.....##.######...##.....##..######.
                    ..##...##...##..##.....##.##.......##.....##.......##
                    ...##.##....##..##.....##.##.......##.....##.##....##
                    ....###....####.########..########..#######...######.
                    */}
                      <Row className="justify-content-center">
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/p5rn8camRnU" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Abonos a Cartera
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/hfs5gKYi7tE" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Bajar cartera
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/pLfUBlNz1UQ" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Consultas a Cartera
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/hWgqdcFwsT0" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Cuentas de Cobro
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/ZyvBp4NJ2s4" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Listado de abonos a cartera y mas
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/Yxm7tXJ_UZc" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                                Facturación y Consultas a Cartera
                            </h6>
                        </Col>
                      </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Cartera;
