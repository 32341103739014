import React from "react";
import Iframe from "react-iframe";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";

// core components

function Escrituracion() {

  return (
    <>
      <div className="section" style={{ backgroundColor: "White", marginTop:"1%" }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <h2 className="text-center title" style={{ color: "#3e4095" }}>
                Escrituración <br></br>
              </h2>
              <div className="space-25"></div>
            </Col>
          </Row>
          <Row className="justify-content-lg-center">
            <Col className="ml-auto mr-auto text-center" lg="12">
              <Card
                style={{
                  width: "100%"
                }}
                className="text-center"
              >
                <CardBody>
                  {/*
                    .##.....##.####.########..########..#######...######.
                    .##.....##..##..##.....##.##.......##.....##.##....##
                    .##.....##..##..##.....##.##.......##.....##.##......
                    .##.....##..##..##.....##.######...##.....##..######.
                    ..##...##...##..##.....##.##.......##.....##.......##
                    ...##.##....##..##.....##.##.......##.....##.##....##
                    ....###....####.########..########..#######...######.
                    */}
                      <Row className="justify-content-center">
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/oCCowA_zZrY" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                                Conciliaciones
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/YL_w7TIOMuk" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Modificar factura
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/OjMyrqRlrhQ" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Alertas y Cumplimiento paso Usuarios Beta 61
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/86tahUb2jNQ" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Consulta de escrituras Trazabilidad
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/lNf0ZyeAxBs" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Consulta escrituras
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/8N8A7SdJOc4" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Cotizacion
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/joWUBKFNfno" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Detenidos y Sancionados
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/hnHZwz_alTo" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Excedentes
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/Eg3ZZxSvO40" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Liquidar Escrituras
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/6SGFDTvNyaY" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Liquidar escrituras Facturar a un tercero
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/PcYfmj1xJgA" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Liquidar Escrituras Interes Social
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/IEYcNftIfNE" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Liquidar y Facturar Escrituras
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/BdBDCUJd81w" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Modifica radicaciones ya factudas
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/XF2fdl7q5GU" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Nota Credito Cambio de factura por fecha
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/dOj-HjA6jbg" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Nota credito Consultar e imprimir
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/6SM_Y7UiAf0" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Nota Credito a Facturas sistema anterior
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/cSeL-ERW3-A" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Notas crédito y cambio de factura por fecha
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/23AzcPIOQD0" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Otorgantes Patrimonio Autónomo
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/nBocgb9amDU" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Radicar Escrituras
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/Bmf2UJ_EP-w" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Seguimiento No autorizada
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/djMGc3M-3ds" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Seguimiento a Radicaciones
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/f7KSdBOR7Kk" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Segumiento Relacion de escrituras por estado
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/JUl9sXLKvk4" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Factura Electronica Envio de Facturas por lotes
                            </h6>
                        </Col>
                      </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Escrituracion;
