import React from "react";
import Iframe from "react-iframe";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";

// core components

function Configuracion() {


  return (
    <>
      <div className="section" style={{ backgroundColor: "White", marginTop:"1%" }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <h2 className="text-center title" style={{ color: "#3e4095" }}>
                Configuración <br></br>
              </h2>
              <div className="space-25"></div>
            </Col>
          </Row>
          <Row className="justify-content-lg-center">
            <Col className="ml-auto mr-auto text-center" lg="12">
              <Card
                style={{
                  width: "100%"
                }}
                className="text-center"
              >
                <CardBody>
                  {/*
                    .##.....##.####.########..########..#######...######.
                    .##.....##..##..##.....##.##.......##.....##.##....##
                    .##.....##..##..##.....##.##.......##.....##.##......
                    .##.....##..##..##.....##.######...##.....##..######.
                    ..##...##...##..##.....##.##.......##.....##.......##
                    ...##.##....##..##.....##.##.......##.....##.##....##
                    ....###....####.########..########..#######...######.
                    */}
                      <Row className="justify-content-center">
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/Q2JkHJXwJVs" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Actualizar Consecutivos
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/E1oJB9UoXEc" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Configuración
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/K_R5BvrMXNM" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Configuración de Tipos de escritura, estadístico
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/f6K5oZZ3hCI" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                              Usuarios
                            </h6>
                        </Col>
                      </Row>
                      <Row className="justify-content-center" style={{marginTop: "2%"}}>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/UDRMDTMgpGU" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                               Acceso Directo
                            </h6>
                        </Col>
                        <Col md="6">
                            <Iframe 
                                width="100%" 
                                url="https://www.youtube.com/embed/ugxCocs75zE" 
                                frameBorder = "0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                              >
                            </Iframe>
                            <br></br>
                            <h6 className="info-title">
                                Editar Tipos de Escrituras
                            </h6>
                        </Col>
                      </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Configuracion;
